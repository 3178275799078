/*==========================
    AILink
============================ */
.ai-link{
  //Yellow Links
  color: $ai-white;
  &.yellow{
    color: $ai-secondary;
  }
  //Hover Effects
  &:hover{
    color: $ai-secondary;
    &.yellow{
      color: $ai-white;
    }
  }
}


/*==========================
    AIButton
============================ */
.ai-button{
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  border: 5px solid $ai-primary;
  font-size: 1.8rem;
  font-weight: 900;
  color: $ai-primary;
  position: relative;
  text-decoration: none;
  overflow: hidden;
  .ai-button__title{
    display: inline-block;
    padding: 1.5rem 0 1.5rem 1.5rem;
    color: $ai-primary;
    z-index: 1;
  }
  .ai-button__icon{
    display: inline-block;
    padding: 1.5rem;
    z-index: 1;
    svg{
      height: 1.8rem;
      path{
        stroke: $ai-primary;
      }
    }
  }
  &:after{
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background-color:$ai-primary;
    transform: translateY(100%);
    transition: transform .5s ease;
    z-index: 0;
  }
  //  Hover Effect
  &:hover{
    .ai-button__title{
      color: $ai-white;
    }

    .ai-button__icon{
      svg{
        path{
          stroke: $ai-white;
        }
      }
    }
    &:after{
      transform: translateY(0);
    }
  }
  &.border-0{
    border: none;
    &:after{
      display: none;
    }
    .ai-button__title{
      padding: 0
    }
    &:hover{
      .ai-button__title{
        color: $ai-black;
      }
      .ai-button__icon{
        svg{
          path{
            stroke: $ai-black;
          }
        }
      }
    }
  }
}

/*==========================
    AIFooter
============================ */
.ai-footer{
  width: 100%;
  background: $ai-primary;
  transition: background .5s ease;
  .ai-footer__contact{
    a{
      display: block;
      padding-top: 5.5rem;
      font-size: 1.6rem;
    }
    @include mobile_all{
      h3{
        font-size: 6rem;
      }
    }
  }
  .ai-footer__navigation{
    padding: 3rem 0 3rem 0;
    ul {
      list-style: none;
      padding-left: 0;
      li {
        line-height: 3.5rem;
        a {
          text-decoration: none;
          font-size: 1.4rem;
        }
      }
    }
  }
  .ai-scroll-top{
    display: inline-block;
    height: 5rem;
    width: 5rem;
    background: $ai-white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg{
      width: 2rem;
      height: 2rem;
    }
    //  Hover Effect
    &:hover{
      background: $ai-primary;
      svg{
        path{
          stroke: $ai-white;
        }
      }
    }
  }
}

/*==========================
    AIHeader
============================ */
.ai-site-header{
  position: fixed;
  width: 100%;
  z-index: 4;
  top: 5rem;
  z-index: 9999;
  @include mobile_all{
    top: 3rem;
  }
  .ai-site-logo{
    display: flex;
    align-items: center;
    height: 50px;
    overflow: hidden;
    .ai-image-wrapper{
      height: 100%;
      max-height: 100%;
      display: flex;
      justify-content: center;
      transition: max-height .5s ease;
      overflow: hidden;
    }
  }
  .ai-navigation-toggle{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;
    height: 5rem;
    @include mobile_all{
      position: absolute;
      top: 0;
      right: 2rem;
    }
    .line{
      height: .5rem;
      background: $ai-primary;
      &:first-child{
        width: 5rem;
        margin-bottom: .8rem;
        transition: transform .5s ease;
      }
      &:last-child{
        width: 3rem;
        transition: width .5s ease;
        transition: transform .5s ease, width .5s ease;
      }
    }
    //  Hover Effect
    &:hover{
      .line{
        &:last-child{
          width: 5rem;
        }
      }
    }
  }
  //  If Navigation Open
  &.ai-navigation-open{
    .ai-site-logo{
      .ai-image-wrapper{
        max-height: 0;
      }
    }
    .ai-navigation-toggle{
      .line{
        margin: 0;
        background-color: $ai-white;
        &:first-child{
          transform: rotate(-45deg);
        }
        &:last-child{
          width: 5rem;
          transform: rotate(45deg) translate(-2px, -3px);
        }
      }
    }
  }
  &.ai-contact-open{
    .ai-navigation-toggle{
      .line{
        margin: 0;
        background-color: $ai-primary;
        &:first-child{
          transform: rotate(-45deg);
        }
        &:last-child{
          width: 5rem;
          transform: rotate(45deg) translate(-2px, -3px);
        }
      }
    }
  }
  &.black{
    svg{
      path{
        fill: $ai-white;
      }
    }
    .ai-navigation-toggle{
      .line{
        background-color: $ai-white;
      }
    }
  }
}


/*==========================
    AIFixed Sidebars
============================ */
.ai-fixed-socials{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
  transform: rotate(-90deg);
  position: fixed;
  top: 0;
  bottom: 0;
  left: -9rem;
  margin: auto;
  z-index: 2;
  transition: left .5s 1s ease;
  a{
    text-transform: uppercase;
    font-weight: $font-w-bold;
    font-size: 1.3rem;
    text-decoration: none;
    color: $ai-primary
  }
  span.ai-spacing-dot{
    padding-left: .5rem;
    padding-right: .5rem;
    font-size: 1.8rem;
  }
  &.visible{
    left: -3rem;
    @include mobile_all{
      display: none;
    }
  }
  &.black{
    a{
      color: $ai-white;
    }
    .ai-spacing-dot{
      color: $ai-white;
    }
  }
}

.ai-fixed-contact{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: $ai-secondary;
  padding: 2rem;
  height: 6rem;
  transform: rotate(90deg);
  position: fixed;
  top: 0;
  bottom: 0;
  right: -20rem;
  margin: auto;
  z-index: 99;
  transition: right .5s 1s ease;
  @include mobile_all{
    background: none;
    transform: rotate(0deg);
    .ai-mobile-contact-toggle{
      svg{
        width: 120px;
      }
    }
  }
  .ai-contact-sidebar__icon{
    width: 6rem;
    svg{
      transform: rotate(-90deg);
      padding-bottom: 1rem;
      border-bottom: 1px solid $ai-primary;
    }
  }
  .ai-contact-sidebar__links{
    transform: rotate(180deg);
    span{
      text-transform: uppercase;
      font-weight: $font-w-bold;
      font-size: 1.3rem;
      text-decoration: none;
      color: $ai-primary
    }
    span.ai-spacing-dot{
      padding-left: .5rem;
      padding-right: .5rem;
      font-size: 1.8rem;
    }
  }
  &:hover{
    cursor: pointer;
  }
  &.visible{
    right: -10.6rem;
    @include mobile_all{
      right: 0;
    }
  }
}

/*==========================
    AINavigation
============================ */
.ai-navigation__wrapper{
  height: 105vh;
  width: 100%;
  background: $ai-primary;
  position: fixed;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0;
  margin: auto;
  z-index: 999 ;
  @include mobile_all{
    top: -5px;
  }
  .ai-navigation__left{
    border-right: 1px solid rgba($ai-white, .3);
    @include mobile_all{
      border-right: none;
      border-bottom: 1px solid rgba($ai-white, .3);
    }
    @include tablet_potrait{
      border-right: none;
      border-bottom: 1px solid rgba($ai-white, .3);
    }
    h1{
      font-size: 20rem;
      margin-left: -1rem;
      @include mobile_all{
        font-size: 6rem;
        margin-left: 0;
      }
    }
    .ai-navigation__menu{
      ul{
        padding: 0;
        list-style: none;
        li{
          a{
            text-decoration: none;
            font-size: 2.8rem;
            line-height: 6rem;
            color: $ai-white;
            display: block;
            transform: translateY(-150px);
            opacity: 0;
            transition: transform 1s ease, opacity 1s ease;
            transition-delay: calc((var(--animation-delay) * -45ms) + 450ms) ;
            &:hover{
              color: $ai-secondary;
            }
          }
        }
      }
    }
  }
  .ai-navigation__right{
    display: flex;
    justify-content: center;
    align-items: center;
    @include mobile_all{
      padding-top: 2rem;
    }
    @include tablet_potrait{
      padding-top: 2rem;
      justify-content: flex-start;
      max-width: 70%;
    }
    .ai-navigation__cta{
      p{
        font-size: 1.3rem;
        opacity: 0;
        transform: translateY(-8rem);
        transition: transform 1s ease, opacity 1s ease;
        transition-delay: .3s
      }
      a{
        display: block;
        font-size: 1.3rem;
        opacity: 0;
        transform: translateY(-8rem);
        transition: transform 1s ease, opacity 1s ease;
        transition-delay: .3s
      }
    }
  }

  //Animations
  transform: translateY(-100%);
  transition: transform 1000ms cubic-bezier(0.19, 1, 0.22, 1) 0s !important;
  .ai-navigation__title{
    h1{
      transform: translateY(-200px);
      opacity: 0;
      transition: transform .7s ease, opacity 1.2s ease;
      transition-delay: .45s;
    }
  }
  &.ai-navigation-open{
    z-index: 999;
    opacity: 1;
    height: 100%;
    width: 100%;
    transform: translateY(0);
    .ai-navigation__title{
      h1{
        transform: translateY(0);
        opacity: 1;
      }
    }
    .ai-navigation__menu{
      ul{
        li{
          a{
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
    }
    .ai-navigation__cta{
      p{
        opacity: 1;
        transform: translateY(0);
      }
      a{
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

/*==========================
    AIAccordion
============================ */
.ai-accordion{

  h2 {
    font-size: 1em;
  }

  .ai-accordion-title-wrapper-careers {
    font-size: 30px !important;
  }

  .ai-accordion-careers-content{
    a {
      color: $ai-black;

      &:hover {
        color: $ai-white;
      }
    }
  }

  .accordion-item{
    border: none;
    padding-bottom: 2rem;
    padding-top: 2rem;
    border-bottom: 1px solid $ai-primary;
    background: none;
    opacity: 0;
    transform: translateY(20rem);
    transition: transform .5s ease, opacity .5s ease;
    transition-delay: calc(var(--animation-delay) * 200ms);
    &:last-child{
      border: none;
    }
    .accordion-header{
      .accordion-button{
        border: none;
        box-shadow: none;
        background: none;
        color: $ai-primary;
        font-size: 1.8rem;
        &:after{
          background-image: url('../assets/images/General/kinga-accordion-icon-open.svg');
        }
        &.collapsed{
          &:after{
            height: 18px;
            width: 18px;
            background-size: cover;
            background-image: url('../assets/images/General/kinga-accordion-icon-closed.svg');
          }
        }
        span.ai-accordion-title-wrapper{
          transition: transform .25s ease;
        }
        &:hover{

        }
      }
      &:hover{
        button{
          color: $ai-secondary;
          &:after{
            background-image: url('../assets/images/General/kinga-accordion-icon-open-yellow.svg');
            background-size: contain !important;
          }
        }
        span.ai-accordion-title-wrapper{
          transform: translateX(20px);
        }
        .collapsed{

          &:after{
            background-image: url('../assets/images/General/kinga-accordion-icon-closed-yellow.svg') !important;
          }
        }
      }
    }
    .accordion-body{
      font-size: 2rem;
      max-width: 850px;
      padding-bottom: 5rem;
    }
    &.collapse{
    }
  }
  &.animate{
    .accordion-item{
      opacity: 1;
      transform: translateY(0);
    }
  }
}

/*==========================
    AISlider
============================ */
.ai-slider-outer-wrapper{
  .ai-slider{
    position: relative;
    margin-bottom: 2rem;
    .swiper-wrapper {
      .swiper-slide {
        opacity: .5;
        .ai-slide-title {
          font-size: 1.8rem;
          font-weight: $font-w-medium;
          padding-bottom: 1rem;
          max-width: 80%;
          @include mobile_all{
            max-width: 95%;
            width: 100%;
          }
        }

        .ai-slide-content {
          //font-size: 1.6rem;
          font-size: 1.8rem;
          max-width: 80%;
          @include mobile_all{
            max-width: 95%;
            width: 100%;
          }
        }
        &.swiper-slide-active{
          opacity: 1;
        }
      }
      &:hover{
        cursor: none;
      }
    }
  }

  .ai-slider-navigation{
    display: flex;
    justify-content: space-between;
    width: 200px;
    flex-direction: row;
    margin-top: 1rem;
    margin-bottom: 2rem;
    .ai-slider-btn{
      border: 6px solid $ai-primary;
      border-radius: 100%;
      width:45px;
      height: 45px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      transition: scale .5s ease;
      &:hover{
        scale: 1.11;
      }
      svg{
        width: 25px;
      }
      &.next{
        transform: rotate(90deg);
      }
      &.prev{
        transform: rotate(270deg);
      }
    }
  }
  @include mobile_all{
    .ai-slider-navigation{
      width: 40%;
    }
  }
}

/*==========================
    AIContactUs
============================ */
.ai-contact-us{
  position: fixed;
  height: 100vh;
  width: 100%;
  left: 0;
  top: -100%;
  background-color: $ai-secondary;
  padding-top: 15rem;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 999;
  transition: top .5s cubic-bezier(.17,.67,1,.76);
  @include mobile_all{
    top: -150%
  }

  p{
    font-size: 2rem;
  }
  .ai-contact-us__title{
    height: 149px;
    overflow: hidden;
    h1{
      transform: translateY(100%);
      transition: transform .8s ease-in-out;
      transition-delay: .3s;
    }
  }
    .ai-contact-us__content-intro{
      height: fit-content;
      overflow: hidden;
    }
    .ai-contact-us__email{
      height: fit-content;
      overflow: hidden;
    }
  .ai-contact-us__ready{
    height: fit-content;
    overflow: hidden;
  }
  .ai-contact-us__email{
    margin-top: 2rem;
    margin-bottom: 2rem;
    span{
      display: block;
    }
    a{
      font-size: 2rem;
      color: $ai-black;
      text-decoration-color: $ai-black;
    }
  }
  .ai-contact-us__ready{
    span{
      font-size: 2.8rem;
      font-weight: 600;
      display: block;
      padding-bottom: 1rem;
    }
  }
  .ai-contact-us__animation-wrapper{
    height: fit-content;
    overflow: hidden;
    p,a,span{
      display: block;
      transform: translateY(110%);
      transition: transform .8s ease-in-out;
      transition-delay: calc(var(--transition-delay) * 150ms);
    }
  }
  &.open{
    top: 0;
    .ai-contact-us__title{
      h1{
        transform: translateY(0);
      }
    }
    .ai-contact-us__animation-wrapper{
      p, a, span{
        transform: translateY(0);
      }
    }
  }
  &.apply-form{
    .ai-contact-us__title{
      height: fit-content !important;
      p{
        font-size: 32px;
        font-weight: 600;
      }
    }
  }
}

/*==========================
    AI Searchbar
============================ */
.ai-searchbar-container{
  border: 1px solid $ai-grey;
  height: 64px;
  border-radius: 50px;
  padding-left: 8rem;
  position: relative;
  .ai-searchbar{
    height: 100%;
    width: 100%;
    margin-right: 2rem;
    border: none;
    background: none;
    font-size: 1.8rem;
    &:focus{
      outline: none;
    }
  }
  .ai-search-icon{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    left: 1rem;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 45px;
    svg{
      height: 100%;
    }
  }
}

/*==========================
    AI Post
============================ */
.ai-archive {
  display: flex;
  flex-wrap: wrap;
  min-height: 569px ;
  .ai-post-card {
    margin-bottom: 5rem;
    width: 33%;
    display: inline-flex;
    @include mobile_all{
      width: 100%;
    }
    @include tablet_potrait{
      width: 50%;
    }
    a {
      text-decoration: none;
      color: inherit;
    }
    .ai-post-card-wrapper {
      width: 90%;
      display: flex;
      flex-direction: column;
      position: relative;
      @include mobile_all{
        width: 100%;
      }
      &:after{
        content: "";
        height: 2px;
        width: 100%;
        max-width: 100%;
        position: absolute;
        bottom: 0;
        background-color: $ai-primary;
        transition: max-width .5s ease-in-out;
      }
    }

    .ai-post-card__image {
      padding-bottom: 2rem;
      height: 300px;
      width: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .ai-post-card__date {
      font-size: 1.8rem;
      color: $ai-grey;
    }

    .ai-post-card__title {
      font-size: 3.8rem;
      color: $ai-primary;
      font-weight: $font-w-bold;
    }

    .ai-post-card__excerpt {
    }

    .ai-post-card__author {
      padding-top: 2rem;
      padding-bottom: 2rem;

      .ai-author__name {
        margin-bottom: 0;
        font-size: 1.8rem;
      }

      .ai-author__role {
        color: $ai-grey;
        font-size: 1.8rem;
      }
    }
  //  Hover Effect
    &:hover{
      .ai-post-card-wrapper{
        &:after{
          max-width: 60%;
        }
      }
    }
  }
}

/*==========================
    AICursor
============================ */
*{
  cursor: none !important;
  @include mobile_all{
    cursor: auto;
  }
}
.ai-cursor{
  height: 150px;
  width: 150px;
  pointer-events: none !important;
  position: fixed;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  z-index: 999999 !important;
  transition: height .5s ease, width .5s ease;
  &.ai-cursor-hover{
    height: 50px;
    width: 50px;
  }
  &.ai-slider-cursor{
    height: 8rem;
    width: 8rem;
    border-radius: 100%;
    z-index: 2;
    background-color: $ai-primary !important;
    mix-blend-mode: normal !important;
    left: 0;
    top: 0;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
      color: $ai-secondary;
      font-size: 1.2rem;
      position: relative;
    }
    &:before{
      content: "";
      background-image: url('../assets/images/General/kinga-slider-arrow.svg');
      position: absolute;
      left: -20px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      height: 20px;
      width: 15px;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
    &:after {
      content: "";
      background-image: url('../assets/images/General/kinga-slider-arrow.svg');
      position: absolute;
      right: -20px;
      transform: rotate(180deg);
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      height: 20px;
      width: 15px;
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &.background-white{
    background-color: $ai-white;
    mix-blend-mode: difference;
    &.ai-slider-cursor{
      span{
        color: $ai-white;
      }
    }
  }
  &.background-yellow{
    background-color: $ai-secondary;
    mix-blend-mode: difference;
  }
  &.background-black{
    background-color: $ai-secondary;
    mix-blend-mode: difference;
  }
  &.background-beige{
    background-color: $ai-secondary;
    mix-blend-mode: difference;
  }
  @include mobile_all{
    display: none;
  }
  @include tablet_potrait{
    display: none;
  }
}

/*==========================
  Splitline Animation
============================ */
.ai-split-text-container{
  //width: calc(100% - 1500px);
  padding: 0 !important;
  position: relative;
  width: 100%;
  display: block;
  //width: 100%;
  .ai-split-line{
    display: block;
    .line{
      display: block;
      transform: translateY(5rem);
      transition: transform .5s ease, opacity .5s ease;
      opacity: 0;
      transition-delay: calc(var(--animation-delay) * 90ms);
      white-space: nowrap;
    }
    &.animate{
      .line{
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

.test-lines{
  display: block;
  visibility: hidden;
  height: 0;
  width: 100%;
  white-space: nowrap;
  overflow: scroll;
  padding: 0 !important;
  margin: 0 !important;
}

/*==========================
  AITitle Animation
============================ */
.ai-title-animation{
  width: auto;
  position: relative;
  overflow: hidden;
  display: inline-block;
  span.title-text{
    visibility: hidden;
    transition-delay: .55s;
    margin-top:  1rem;
  }
  .title-overlay{
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 100%;
    background: $ai-primary;
    transform: translate3d(-100%, 0, 0);
    transition: transform 1.5s ease;
  }
  &.animated{
    span.title-text{
      visibility: visible;
    }
    .title-overlay{
      transform: translate3d(105%, 0, 0);
    }
  }
}

.ai-animated-line-wrapper{
  h1,h2,h3,h4,h5,h6{
    &:first-child{
      margin-bottom: 0 !important;
    }
  }
}


/*==========================
  AIPagination
============================ */
.ai-pagination{
  text-align: center;
  .ai-pagination-link{
    font-size: 1.6rem;
    padding: 0 1rem;
    text-decoration: none;
    color: $ai-grey;
    border-radius: 100%;
    height: 3.5rem;
    width: 3.5rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    &.active{
      background: $ai-primary;
      color: $ai-white;
    }
  }
}

/*==========================
    AI Lazy loader
============================ */
.ai-lazy-postcard{
  height: 569px;
  width: 33%;
  margin-bottom: 9rem;
  @include mobile_all{
    width: 100%;
  }
  @include tablet_potrait{
    width: 50%;
  }
  .ai-lazy-postcard__wrapper{
    width: 90%;
    background: $ai-white;
    height: 100%;
    @include mobile_all{
      width: 100%;
    }
    .ai-lazy-postcard__image{
      height: 300px;
      margin-bottom: 2rem;
    }
    .ai-lazy-postcard__content{
      height: calc(569px - 320px)
    }
    .ai-lazy-postcard__gradient{
      opacity: 0.5;
      background: linear-gradient(135deg, rgba(211,223,232,.5) 30%,rgba(169,184,197,.5) 38%,rgba(168,182,193,.6) 41%,rgba(168,182,193,.6) 50%,rgba(168,182,193,.6) 50%,rgba(169,184,197,.6) 51%,rgba(211,223,232,.6) 57%,rgba(211,223,232,.6) 80%,rgba(211,223,232,.6) 80%);
      background-size: 1200% 1200%;
      animation: AnimationName 2s ease infinite reverse;
      height: 100%;
    }
  }
}
@keyframes AnimationName {
  0%{background-position:0% 92%}
  50%{background-position:100% 9%}
  100%{background-position:0% 92%}
}


/*==========================
    AIAccordion Carousel
============================ */
.ai-carousel-accordion{
  .ai-slider{
    margin-bottom: 2rem;
    .swiper-slide{
      .ai-slide-title{
        max-width: 99%;
      }
      .ai-slide-content{
        max-width: 95%;
      }
      .ai-index{
        font-size: 12rem;
        line-height: 12rem;
      }
    }
  }
  .ai-slider-navigation{
    margin-bottom: 4rem;
  }
  .ai-outer-accordion {
    & > .accordion-item{
      border: none !important;
      padding-bottom: 1rem;
      padding-top: 1rem;

      & > .accordion-header{
        padding: 1rem;
        border: 1px solid $ai-primary;
        .ai-accordion-title-wrapper{
          font-size: 2.2rem !important;
        }
        
      }
      & > .accordion-collapse{
        .accordion-body{
          max-width: 93%;
          margin: 0 auto;
          padding-bottom: 0;
        }
      }
    }
    .ai-inner-accordion{
      .accordion-body{
        max-width: 90% !important;
        margin-left: 0 !important;
        padding-left: 0;
      }
      .accordion-button{
        padding-left: 0;
      }
    }
  }
}

.ai-glitch-content {
  opacity: 1;
  transition-delay: 0.2s;
}

.animate-out-text {
  opacity: 0;
}

/*==========================
   AIFORMS
============================ */
form.ai-form{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
  .ai-form-field{
    display: inline-flex;
    flex-direction: column;
    label{
      font-size: 14px;
      margin-bottom: .5rem;
    }
    input{
      border: 2px solid $ai-primary;
      background: none;
      height: 50px;
      border-radius: 4px;
      font-size: 20px;
      font-weight: 600;
      padding: .5rem;
    }
    &:not(.textarea){
      width: 48%;
      @include mobile_all{
        width: 100%;
      }
    }
    &.checkbox{
      flex-direction: column !important;
    }
    &.textarea, &.checkbox{
      width: 100%;
    }

    &.upload{
      label{
        //visibility: hidden;
        height: 50px;
        margin-top: 2.6rem;
        margin-bottom: 0;
        border: 7px solid $ai-primary;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 23px;
        cursor: pointer;
        &:hover{
          background: $ai-primary;
          color: $ai-secondary;
        }
      }
      input{
        display: none;
      }
      span.ai-input-file{

      }
    }
    &.checkbox{
      display: flex;
      flex-direction: row;
      margin-top: 2rem;
      margin-bottom: 2rem;
      input{
        visibility: hidden;
        position: absolute;
        &:checked{
          & ~ .ai-checkbox{
            background: $ai-primary;
          }
        }
      }
      label{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ai-checkbox{
          height: 30px;
          width: 30px;
          border: 4px solid $ai-primary

        }
        .ai-checkbox-content{
          width: 90%;
          font-size: 14px;
        }
      }
    }
    .ai-error-message{
      margin-top: .5rem;
      color: #c80202;
      font-size: 14px;
    }
  }
  .ai-button-submit{
    background: none;
    border: none;
    //display: none;
    span.ai-submit-icon{
      position: relative;
      border: 7px solid $ai-primary;
      margin-left: 2rem;
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      svg{
        transform: rotate(90deg);
      }
      &:after{
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        background: $ai-primary;
        height: 100%;
        width: 100%;
        z-index: -1;
        transition: transform 0.3s ease;
      }
    }
    &:after{
      content: unset;
    }
    &:hover{
      span.ai-submit-icon{
        svg{
          path{
            stroke: $ai-secondary
          }
        }
        &:after{
          transform: translateY(-100%);
        }
      }
    }
  }
}
.ai-form-thank-you{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg{
    width: 100px;
    margin-bottom: 2rem;
    path{
      fill: $ai-primary;
    }
  }
  p{
    margin-bottom: 1rem;
  }
}

//Black Circle
.ai-black-circle{
  width: 200px;
  height: 200px;
  position: absolute;
  background: $ai-primary;
  border-radius: 100%;
}

//Yellow Circle
.ai-yellow-circle{
  width: 200px;
  height: 200px;
  position: absolute;
  background: $ai-secondary;
  border-radius: 100%;
}

//Career Value Blocks
.ai-career-value-block{
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2rem;
  .ai-career-value-icon{
    margin-top: 1rem;
    width: 20px;
    height: 20px;
    display: inline-block;
    aspect-ratio: 1/1;
    svg{
      width: 100%;
      height: 100%;
      path{
        stroke: $ai-white;
      }
    }
  }
  .ai-career-value-content{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
.ai-bg-blue{
  .ai-career-value-block{
    .ai-career-value-icon{
      svg{
        path{
          stroke: $ai-white;
        }
      }
    }
  }
}